import React from 'react';
import CommonSection3 from '../common/Section3';
import map_ubicacion from "../../../images/index/map_ubicacion.jpg"
const Section3 = () => {
    return (
        <>
        <div style={{textAlign:'left', fontSize:'0.85rem'}} className='p-3 pt-4'>
            <CommonSection3 />
        </div>
        <div>
            <img src={map_ubicacion} alt="" className="img-fluid" />
        </div>
        </>
    );
};

export default Section3;