import React from "react"
import { FormattedMessage } from "react-intl";

const Section2 = (props) => {
    const {camino} = props;
    return (
        <div className="inside-bg-holder container-fluid">
        <div id="imagen_01">
            <h4>
            <FormattedMessage id="valle-de-uco" defaultMessage="valle-de-uco" />
            </h4>
            <div className="paragraph-wrapper">
                <p>
                    <FormattedMessage
                    id="valle-de-uco-es-el-valle"
                    defaultMessage="valle-de-uco-es-el-valle"
                    />
                </p>
                <p>
                    <FormattedMessage
                    id="diferentes-altitudes"
                    defaultMessage="diferentes-altitudes"
                    />
                </p>
            </div>
            {props.pageContext.language === "en" ? (
            <>
                <img
                src={`${camino}/lafinca/01_valledeuco_a_en.png`}
                alt=""
                className="mw-100"
                />
            </>
            ) : (
            <>
                <img
                src={`${camino}/lafinca/01_valledeuco_a.png`}
                alt=""
                className="mw-100"
                />
            </>
            )}
        </div>
        <div id="imagen_02">
            <h4>Mendoza</h4>
            <img
            src={`${camino}/lafinca/01_valledeuco_b.png`}
            alt=""
            className="mw-100"
            />
        </div>
        <div id="imagen_03">
            <h4>Argentina</h4>
            <img
            src={`${camino}/lafinca/01_valledeuco_c.png`}
            alt=""
            className="mw-100"
            />
        </div>
        </div>
    )
}

export default Section2
