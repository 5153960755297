import React from 'react';
import { FormattedMessage } from "react-intl";
import logoHome from "../../../images/logo-home.png"

const VideoHeader = () => {
    return (
        <>
            <div className="video-wrapper position-absolute w-100 h-100">
                    <iframe
                        title="video introduction"
                        frameBorder="0"
                        height="100%"
                        width="100%"
                        src="https://youtube.com/embed/5UFZX2iFWr0?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=5UFZX2iFWr0&modestbranding=1"
                        style={{width:'400%', left:'-200%'}}
                    >
                    </iframe>
                </div>
                <div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center text-white container">
                    <div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "zIndex": "1", "left": "0" }}></div>
                    <div style={{ "zIndex": "2" }}> <img src={logoHome} alt="" className="logo-home" /></div>

                    <p className="col-8 offset-2 text-center" style={{ "zIndex": "2" }}>
                        <FormattedMessage id="terrunio-unico" defaultMessage="terrunio-unico" />
                    </p>
                </div>
        </>
    );
};

export default VideoHeader;