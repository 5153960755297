import React from 'react';
import map_ubicacion from "../../../images/index/map_ubicacion.jpg"
import point_map_pasaje_altamira from "../../../images/index/point_map_pasaje_altamira.png"
import point_map_la_celia from "../../../images/index/point_map_la_celia.png"
import point_map_eugenio_bustos from "../../../images/index/point_map_eugenio_bustos.png"
import point_map_la_consulta from "../../../images/index/point_map_la_consulta.png"
import CommonSection3 from '../common/Section3';
const Section3 = () => {
    return (
        <div className="container content-wrapper d-flex align-items-center">
        <div className="row ">
            <div className="col-md-4 text-start pt-0 ps-4 texto-con-scroll">
               <CommonSection3 />
            </div>
            <div className="col-md-8 position-relative">
                <div className="position-relative">
                    <img src={map_ubicacion} alt="" className="img-fluid" />
                    <ul id="map" className="position-absolute top-0" >
                        <li title="pasaje Altamira" style={{ top: "36%", left: "40%" }} className="dotMap">
                            <img src={point_map_pasaje_altamira} style={{ top: "-85px", left: "-134px" }} alt="" />
                        </li>
                        <li title="La Celia" style={{ top: "84%", left: "27%" }} className="dotMap">
                            <img src={point_map_la_celia} style={{ top: "-85px", left: "-24px" }} alt="" />
                        </li>
                        <li title="eugenio Bustos" style={{ top: "89%", left: "69%" }} className="dotMap">
                            <img src={point_map_eugenio_bustos} style={{ top: "-71px", left: "-87px" }} alt="" />
                        </li>
                        <li title="la consulta" style={{ top: "41%", left: "87%" }} className="dotMap">
                            <img src={point_map_la_consulta} style={{ top: "-71px", left: "-87px" }} alt="" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Section3;