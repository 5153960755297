import React, { useEffect } from "react"

import Layout from "../components/layout"
import "../components/index/styles.scss"
import { CuartelesDeLaFinca } from "../components/index/cuartelesDeLaFInca"

import SimpleLocalize from "../components/SimpleLocalize";
import img01 from "../images/home-01.jpg"
import valleDeUco from "../images/index/valle-de-uco.jpeg"
import Section from "../components/common/Section";
import { Helmet } from "react-helmet"
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import DesktopVideoHeader from "../components/index/desktop/VideoHeader";
import MobileVideoHeader from "../components/index/mobile/VideoHeader";
import DesktopSection2 from "../components/index/desktop/Section2";
import MobileSection2 from '../components/index/mobile/Section2';
import DesktopSection3 from '../components/index/desktop/Section3';
import MobileSection3 from '../components/index/mobile/Section3';
import Video360 from "../components/index/mobile/Video360";
const IndexPage = (props) => {
	let camino = '..'
	//let poslang = '';
	if (props.pageContext.language === 'en') {
		camino = '../..';
		//poslang = "_en";
	}

	useEffect(() => {
		var viewport = document.getElementsByTagName('body')[0];
		const checkPos = (e) => {
			var a = viewport.scrollTop;
			var b = viewport.scrollHeight - viewport.clientHeight;
			var c = a / b;
			console.log(c)
			if (c <= 0.3333333333333333 && c > 0 && document.querySelector("#imagen_01")) {
				document.querySelector("#imagen_01").classList.add("image_01_animate");
				document.querySelector("#imagen_02").classList.add("image_02_animate");
				document.querySelector("#imagen_03").classList.add("image_03_animate");
			}
			if (c === 0.4) {
				var dotMap = document.querySelectorAll(".dotMap");
				dotMap.forEach(function (userItem) {
					userItem.classList.add("dotanimate");
				});
			}
		}
		viewport.addEventListener('scroll', checkPos, false);
	}, [])

	return (
		<SimpleLocalize {...props}>
			<Layout page='index' {...props}>
				<Helmet>
					<body />
				</Helmet>
				<Section id="seccion1" sectionClasses="py-0 text-center position-relative min-vh-100 mobile-section" childClasses="bg-holder" childStyle={{backgroundImage:`url(${img01})` }}>
					<BrowserView>
						<DesktopVideoHeader />
					</BrowserView>
					<MobileView>
						<MobileVideoHeader />
					</MobileView>
				</Section>
				<Section id="seccion2" sectionClasses="py-0 text-center position-relative min-vh-100" childStyle={{backgroundImage:`url(${valleDeUco})` }} childClasses={isMobile ? 'min-vh-100' : 'bg-holder'}>
					<BrowserView>
						<DesktopSection2 camino={camino} {...props}/>
					</BrowserView>
					<MobileView>
						<MobileSection2 camino={camino} {...props}/>
					</MobileView>
				</Section>
				<Section id="seccion3" sectionClasses="py-0 text-center position-relative min-vh-100" childClasses={isMobile ? '' : 'bg-holder no-top'}>
					<BrowserView>
						<DesktopSection3 />
					</BrowserView>
					<MobileView>
						<MobileSection3 />
					</MobileView>
				</Section>
				{/*<section className="py-0 text-center position-relative min-vh-100">
					<CuartelesDeLaFinca {...props} />
				</section>*/}
				<BrowserView>
					<Section id="seccion5" sectionClasses="py-0 text-center position-relative min-vh-100" childClasses={isMobile ? '' : 'bg-holder no-top'}>
							<iframe src={`https://terroir.laceliawines.com/tour/valledeuco.html?lang=${props.pageContext.language}`} title="Valle de UCO" className="w-100 h-100">
								<p>Your browser does not support iframes.</p>
							</iframe>
					</Section>
				</BrowserView>
				<MobileView>
					<Video360  {...props} />
				</MobileView>
			</Layout>
		</SimpleLocalize>)
}

export default IndexPage
