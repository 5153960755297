import React from "react"
import { FormattedMessage } from "react-intl"

const paragraphStyle = {
    textAlign: "center",
    zIndex:'2',
    position:'relative',
    fontSize:'0.9rem'
}

const Section2 = props => {
  const { camino } = props
  return (
    <div>
      <h3 className="p-1 pt-5">
        <FormattedMessage id="valle-de-uco" defaultMessage="valle-de-uco" />
        <br />
        Mendoza, Argentina
      </h3>
      <div className="paragraph-wrapper p-3">
        <p style={paragraphStyle}>
          <FormattedMessage
            id="valle-de-uco-es-el-valle"
            defaultMessage="valle-de-uco-es-el-valle"
          />
        </p>
        <p style={paragraphStyle}>
          <FormattedMessage
            id="diferentes-altitudes"
            defaultMessage="diferentes-altitudes"
          />
        </p>
      </div>
      <img
          src={`${camino}/lafinca/valledeuco_mobile.png`}
          alt=""
          className="mw-100 position-absolute bottom-0 start-0 pe-2"
        />
    </div>
  )
}

export default Section2
